import React, { forwardRef, useEffect, useRef } from 'react';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';

const MarkdownEditor = forwardRef((props, ref) => {
  console.log(props.value);

  useEffect(() => {
    if (ref.current) {
      const editorInstance = ref.current.getInstance();
      editorInstance.setMarkdown(props.value);
    }
  }, [props.value]);

  return (
    <Editor
      ref={ref}
      previewStyle="vertical"
      height="350px"
      initialEditType="markdown"
      useCommandShortcut={false}
      initialValue={props.value}
      onChange={props.onChange}
      toolbarItems={[
        // 툴바 옵션 설정
        ['heading', 'bold', 'italic', 'strike'],
        ['hr', 'quote'],
        ['image', 'link'],
        ['code', 'codeblock'],
      ]}
    />
  );
});

export default MarkdownEditor;
