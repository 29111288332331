import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Grid,
  Box,
  Typography,
  Container,
  InputAdornment,
  Divider,
} from '@mui/material/';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Lock } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getMyInfo } from '@store/actions/userAction';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setUserInfo } from '@store/actions/commonAction';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PwEdit from './PageMypagePwEdit';
import TelEdit from './PageMypageTelEdit';
import ConfirmPopUp from 'component/ConfirmPopUp';

const PageMypageEdit = () => {
  //const location = useLocation();
  // const user = location.state;
  const user = useSelector((state) => state.commonReducer.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = createTheme();
  const [pwOpen, setPwOpen] = useState(false);
  const [telOpen, setTelOpen] = useState(false);
  const [deletePop, setDeletePop] = useState(false);
  const [myInfo, setMyInfo] = useState([]);

  const handlePwOpen = () => {
    setPwOpen(true);
  };

  const handleTelOpen = () => {
    setTelOpen(true);
  };

  const handleTelUpdate = (newTel) => {
    setMyInfo((prevInfo) => ({ ...prevInfo, mblNo: newTel }));
  };

  useEffect(() => {
    dispatch(
      getMyInfo({
        userSn: user.userSn,
        userId: user.userId,
        userPw: user.userPw,
        mblNo: user.mblNo,
        callback: getMyInfoCallback,
      })
    );
  }, [dispatch]);

  const getMyInfoCallback = (data) => {
    setMyInfo(data.data);
  };

  const handleDeleteUser = () => {};

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container alignItems={'center'} padding={'16px 30px'} borderBottom={'1px solid lightgray'}>
          <Grid item>
            <ArrowBackIosIcon onClick={() => navigate('/user/pageMypage')} style={{ cursor: 'pointer' }} />
          </Grid>
          <Grid item>
            <Typography variant="h6" fontWeight={'bold'}>
              내 정보
            </Typography>
          </Grid>
        </Grid>
        <Container component="main" maxWidth="none">
          <Box
            sx={{
              padding: '40px 45px ',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Box component="form" noValidate>
              <FormControl component="fieldset" variant="standard">
                {/* {myInfo &&
                  myInfo.data &&
                  myInfo.data.map((item, index) => ( */}
                <Grid container spacing={2}>
                  <Grid item container spacing={2} alignItems={'flex-end'}>
                    <Grid item xs={1.5}>
                      <Typography variant="subtitle1" className="rqd">
                        이름
                      </Typography>
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        variant="standard"
                        size="small"
                        fullWidth
                        id="userNm"
                        name="userNm"
                        value={myInfo.userNm ? myInfo.userNm : ''}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2} alignItems={'flex-end'}>
                    <Grid item xs={1.5}>
                      <Typography variant="subtitle1" className="rqd">
                        아이디
                      </Typography>
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        variant="standard"
                        size="small"
                        fullWidth
                        name="userId"
                        me="userId"
                        value={myInfo.userId ? myInfo.userId : ''}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2} alignItems={'flex-end'}>
                    <Grid item xs={1.5}>
                      <Typography variant="subtitle1" className="rqd">
                        비밀번호
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                      <TextField
                        variant="standard"
                        size="small"
                        fullWidth
                        id="userPw"
                        name="userPw"
                        type="password"
                        value={myInfo.userPw ? myInfo.userPw : ''}
                        //onChange={handleChange}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <Button className="btn" type="button" onClick={handlePwOpen}>
                        비밀번호 변경
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2} alignItems={'flex-end'}>
                    <Grid item xs={1.5}>
                      <Typography variant="subtitle1" className="rqd">
                        휴대폰 번호
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                      <TextField
                        variant="standard"
                        size="small"
                        fullWidth
                        id="mblNo"
                        name="mblNo"
                        value={myInfo.mblNo ? myInfo.mblNo : ''}
                        //onChange={handleChange}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <Button className="btn" type="button" onClick={handleTelOpen} sx={{ width: '109px' }}>
                        번호 변경
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {/* ))} */}
              </FormControl>
            </Box>
          </Box>
          <Divider sx={{ borderColor: 'gray' }} />
          <Grid container item xs={12} justifyContent={'flex-end'}>
            <Button type="button" sx={{ color: 'grey' }} onClick={() => setDeletePop(true)}>
              회원 탈퇴 <NavigateNextIcon />
            </Button>
          </Grid>
        </Container>
      </ThemeProvider>
      {pwOpen && <PwEdit pwOpen={pwOpen} setPwOpen={setPwOpen} />}
      {telOpen && <TelEdit telOpen={telOpen} setTelOpen={setTelOpen} handleTelUpdate={handleTelUpdate} />}
      {deletePop && (
        <ConfirmPopUp
          open={deletePop}
          close={setDeletePop}
          title={'회원 탈퇴 처리 하시겠습니까?'}
          comment={'탈퇴 후에는 되돌릴 수 없으며\n기존의 데이터는 모두 삭제됩니다.'}
          btnNm1={'아니요'}
          btnNm2={'네'}
          btnEvent={handleDeleteUser}
        />
      )}
    </>
  );
};

export default PageMypageEdit;
