import { Box, Button, Card, CardContent, Container, createTheme, CssBaseline, Typography } from '@mui/material';
import { setUserJoin } from '@store/actions/userAction';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

const PageSearchTypeMain = () => {
  const defaultTheme = createTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchType = useSelector((state) => state.commonReducer.searchType);

  const [flag, setFlag] = useState('');

  const handleChange = (flag) => {
    setFlag(flag);
  };

  const handleNext = () => {
    dispatch(
      setUserJoin({
        userFlag: flag,
      })
    );

    if (searchType === 'id') {
      navigate('/searchId');
    } else {
      navigate('/searchPw');
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs" sx={{ minHeight: '100%', alignContent: 'center' }}>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%', // 너비를 100%로 설정
          }}
        >
          <Box display={'flex'} alignItems={'center'} mb={3}>
            <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
              <img src="/logo.png" style={{ width: '150px' }} />
            </Box>
            <Typography variant="h6">{searchType === 'id' ? '아이디' : '비밀번호'}&nbsp;찾기</Typography>
          </Box>
          <Box component="form" noValidate sx={{ mt: 1, width: '100%' }}>
            <Box noValidate>
              <Box display={'flex'} alignItems={'center'}>
                <Typography color={'primary'} fontWeight={'bold'}>
                  가입 유형
                </Typography>
                <Typography fontWeight={'bold'}>&nbsp;선택</Typography>
              </Box>
              <Typography>가입하신 계정의 유형을 선택해주세요</Typography>
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  textAlign: 'left',
                  borderRadius: 4,
                  width: '100%',
                  mt: 3,
                  border: flag === 'D' ? '1px solid #f8c9a8' : '', // Conditional border
                  background: flag === 'D' ? '#fef8f4' : 'inherit', // Conditional background
                }}
                className="radio-btn2 orange"
                onClick={() => handleChange('D')}
              >
                <CardContent>
                  <Typography color="text.primary" variant="body4">
                    관리사/개발사
                  </Typography>
                  <Typography variant="body2">의뢰사와 개발사 간의 관리를 하는 회사 또는 개발회사</Typography>
                </CardContent>
              </Card>

              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  textAlign: 'left',
                  borderRadius: 4,
                  width: '100%',
                  mt: 3,
                  border: flag === 'E' ? '1px solid #f8c9a8' : '', // Conditional border
                  background: flag === 'E' ? '#fef8f4' : 'inherit', // Conditional background
                }}
                onClick={() => handleChange('E')}
              >
                <CardContent>
                  <Typography color="text.primary" variant="body4">
                    프리랜서/개발사 직원
                  </Typography>
                  <Typography variant="body2">PMT를 이미 사용하고 있는 회사에 고용된 프리랜서 또는 직원</Typography>
                </CardContent>
              </Card>

              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  textAlign: 'left',
                  borderRadius: 4,
                  width: '100%',
                  mt: 3,
                  border: flag === 'C' ? '1px solid #f8c9a8' : '', // Conditional border
                  background: flag === 'C' ? '#fef8f4' : 'inherit', // Conditional background
                }}
                onClick={() => handleChange('C')}
              >
                <CardContent>
                  <Typography color="text.primary" variant="body4">
                    의뢰사
                  </Typography>
                  <Typography variant="body2">PMT를 사용하고 있는 회사에 프로젝트를 의뢰한 회사</Typography>
                </CardContent>
              </Card>

              <Button type="button" fullWidth variant="contained" sx={{ mt: 5 }} onClick={handleNext} disabled={!flag}>
                다음
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default PageSearchTypeMain;
